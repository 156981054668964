import { template as template_1cda7e6d122540d5a23c170cb348bc38 } from "@ember/template-compiler";
const SidebarSectionMessage = template_1cda7e6d122540d5a23c170cb348bc38(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
