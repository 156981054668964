define("discourse/plugins/discourse-login-helper/discourse/initializers/login_helper", ["exports", "discourse/controllers/email-login", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/lib/url"], function (_exports, _emailLogin, _ajax, _ajaxError, _url) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "login-helper",
    initialize() {
      _emailLogin.default.reopen({
        actions: {
          finishLogin() {
            let data = {
              second_factor_method: this.secondFactorMethod,
              timezone: moment.tz.guess()
            };
            if (this.securityKeyCredential) {
              data.second_factor_token = this.securityKeyCredential;
            } else {
              data.second_factor_token = this.secondFactorToken;
            }
            (0, _ajax.ajax)({
              url: `/session/email-login/${this.model.token}`,
              type: "POST",
              data
            }).then(result => {
              if (result.success) {
                // This is the only change from the original code
                let destination = result.destination_url || "/";
                const safeMode = new URL(this.router.currentURL, window.location.origin).searchParams.get("safe_mode");
                if (safeMode) {
                  const params = new URLSearchParams();
                  params.set("safe_mode", safeMode);
                  destination += `?${params.toString()}`;
                }
                _url.default.redirectTo(destination);
              } else {
                this.set("model.error", result.error);
              }
            }).catch(_ajaxError.popupAjaxError);
          }
        }
      });
    }
  };
});