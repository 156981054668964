import { template as template_ffe4e1b6d3834032b921a92e7d36462a } from "@ember/template-compiler";
const EmptyState = template_ffe4e1b6d3834032b921a92e7d36462a(`
  <div class="empty-state-container">
    <div class="empty-state">
      <span data-test-title class="empty-state-title">{{@title}}</span>
      <div class="empty-state-body">
        <p data-test-body>{{@body}}</p>
      </div>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default EmptyState;
