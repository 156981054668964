import { template as template_68c9fe3b4cff4989afb4ab2848a906cf } from "@ember/template-compiler";
const FKControlMenuContainer = template_68c9fe3b4cff4989afb4ab2848a906cf(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
