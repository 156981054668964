import { template as template_849df3da78d84236a916409a05aa8be4 } from "@ember/template-compiler";
const WelcomeHeader = template_849df3da78d84236a916409a05aa8be4(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
